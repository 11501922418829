<template>
    <div>
        <div class="widget--wrapper">


            <button v-if="refreshable"
                    class="btn btn-link float-right"
                    @click="$emit('refresh')">
                <span class="fa fa-refresh"></span>
            </button>
            <div class="float-right" v-if="useDateFilter">
                <font-awesome-icon icon="calendar" class="text-gray" title="Filter på datum" />
            </div>
            <h4>{{ title }} <b-badge variant="secondary" v-if="badge">{{badge}}</b-badge></h4>

            <div :class="{ 'resizeable': resizeable }" :style="`height:${maxHeight}px;`">
                <slot name="body" />
            </div>
            <div v-if="!!$slots.footer">
                <slot name="footer" />
            </div>
        </div>
        <slot />
    </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
        },
        badge: {
            type: Number,
            required: false,
        },
    maxHeight: {
      type: Number,
      default: 430,
    },
    refreshable: {
      type: Boolean,
      default: false,
    },
    resizeable: {
      type: Boolean,
      default: true
        },
        useDateFilter: {
            type: Boolean,
            default: false
        }
  },
};
</script>

<style scoped>
.panel-body {
  overflow-y: auto;
}

.resizeable {
  resize: vertical;
}

    .widget--wrapper {
        background-color: #fffefc;
        border: 1px solid #e8e8e8;
        padding: 15px;
        -webkit-box-shadow: 1px 1px 3px #313a46;
        box-shadow: 1px 1px 3px #c5c5c5;
    }
</style>