<template>
    <WidgetWrapper :title="widget.title"
                   :badge="totalRows"
                   :maxHeight="widget.maxHeight"                   
                   :useDateFilter="settings.useDateFilter"
                   :refreshable="settings.refreshable"
                   @refresh="getData()">
        <template v-slot:body>
            <Loader v-if="isLoading" :text="'Laddar...'" />
            <p v-else-if="errorMessage">{{errorMessage}}</p>
            <b-table :items="tableData"
                     :fields="settings.fields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :sort-by.sync="sortBy"
                     :sort-desc.sync="sortDesc"
                     :sort-direction="sortDirection"
                     :busy="isLoading"
                     stacked="sm"
                     show-empty
                     small>
            </b-table>
            <b-pagination v-model="currentPage"
                          :total-rows="totalRows"
                          :per-page="perPage"
                          align="left"
                          size="sm"
                          hide-goto-end-buttons
                          hide-ellipsis></b-pagination>
        </template>
    </WidgetWrapper>
</template>

<script>
    import { get } from '@/helpers/api';
    import Loader from "@/components/Loader";
    import WidgetWrapper from "@/components/widgets/WidgetWrapper";


    export default {
        name: 'TableWidget',
        components: { Loader, WidgetWrapper },
        props: ["widget", "customerId", "startDate", "endDate"],
        data() {
            return {
                isLoading: true,            
                tableData: [],
                settings: {
                    trackRowBy: "id",
                    clickableRows: false,
                    refreshable: false,
                    fields: [],
                },
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [5, 10, 25, 50, 100],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                errorMessage: '',
            };
        },
        mounted() {
            this.getData(false);

            if (this.widget.RefreshInterval > 0)
                this.interval = setInterval(() => {
                    this.getData(true);
                }, 1000 * this.widget.RefreshInterval);
        },
        methods: {
  
            async getData(silentReload) {
                if (!silentReload) this.isLoading = true;

                this.errorMessage = "";

                await get('Dashboard', `GetWidget/${this.widget.id}?start=${this.startDate}&end=${this.endDate}`)
                    .then((x) => {
                            
                        this.tableData = x.data.data;
                        this.totalRows = this.tableData.length;
                        this.settings = JSON.parse(x.data.settings);
                        this.isLoading = false;
      
                        })
                    .catch((x) => {
                        this.isLoading = false;
                        });

            }
      
        },
        computed: {
            pagination() {
                if (!this.tableSettings.pageSize) return;

                return {
                    totalItems: this.tableData.length,
                    pageSize: this.tableSettings.pageSize,
                };
            },
        },
        watch: {
            startDate(newVal, oldVal) {
                if (this.settings.useDateFilter)
                    this.getData(true);
            },
            endDate(newVal, oldVal) {
                if (this.settings.useDateFilter)
                    this.getData(true);
            }
        }
    };
</script>