<template>
    <div>
        <h4>
            <b-button variant="light"
                      @click="showFilter = !showFilter"
                      class="border-gray-light"
                      v-b-tooltip.hover
                      :title="(!showFilter ? 'Visa' : 'Dölj') + ' filter'"
                      size="sm"><font-awesome-icon icon="filter" /></b-button>
            {{header}}
        </h4>

        <b-input-group size="sm"
                       v-show="showFilter">
            <b-form-input id="filter-input"
                          v-model="filter"
                          type="search"
                          size="sm"
                          autocomplete="off"
                          placeholder="Filtrera...." />
            <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                    Rensa
                </b-button>
            </b-input-group-append>
        </b-input-group>
        <div class="widget-table-wrapper">
            <!--TABLE-->
            <b-table :items="lacks"
                     :fields="fields"
                     :sort-by.sync="sortBy"
                     :sort-desc.sync="sortDesc"
                     :sort-direction="sortDirection"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="filter"
                     :busy="isLoading"
                     :filter-included-fields="['concatenatedNumbers', 'articleName', 'lackDescription', 'fixedStatusDescription']"
                     @filtered="onFiltered"
                     @context-changed="onContextChanged"
                     stacked="sm"
                     show-empty
                     fixed
                     small
                     >
                <template #cell(status)="row">
                    <div class="overflow-hidden">
                        <span class="d-none d-xs-inline-block">Status: </span>
                        <ControlPositionStatusBall :status-id="row.item.statusId" />
                    </div>
                </template>
                <template #cell(buildingName)="row">
                    <div class="overflow-hidden">
                        <router-link :to="{ name: 'manage-building', params: { id: row.item.buildingId, tab: 'lacks', entityId: row.item.id } }">
                            {{row.item.buildingName}}
                        </router-link>
                    </div>
                </template>
                <template #cell(concatenatedNumbers)="row">
                    <div class="overflow-hidden">
                        {{row.item.controlSerialNumber}}-{{ row.item.concatenatedNumbers }}
                    </div>
                </template>
                <template #cell(articleName)="row">
                    <div class="overflow-hidden">
                        <img v-if="row.item.fileSymbolId"
                             style="height: 1.5rem"
                             :src="getUrl(row.item.articleId)" />
                        {{ row.item.articleName }}
                    </div>
                </template>
                <template #cell(lackDescription)="row">
                    <div class="overflow-hidden">
                        {{ row.item.lackDescription }}
                    </div>
                </template>
                <template #cell(fixedStatusDescription)="row">
                    <div class="overflow-hidden">
                        {{ row.item.fixedStatusDescription }}
                    </div>
                </template>
                <template #cell(hasAttachement)="row">
                    <font-awesome-icon v-if="row.item.hasAttachements"
                                       icon="paperclip"
                                       title="Bilaga/or finns" />
                </template>
                <template #cell(controlDoneDate)="row">
                    <div class="overflow-hidden">
                        {{ row.item.controlDoneDate | datetime('yyyy-MM-dd') }}
                    </div>
                </template>
                <template #table-busy>
                    <div class="text-center mt-4">
                        <font-awesome-icon class="align-middle"
                                           icon="spinner"
                                           size="2x"
                                           spin />
                    </div>
                </template>
                <template #empty="">
                    <h4 class="text-center">Det finns inga brister</h4>
                </template>
                <template #emptyfiltered="">
                    <div class="text-center">
                        <h4>Inga resultat</h4>
                        <h6>
                            <a href="#" @click="filter = ''">
                                Klicka här för att rensa aktiv filtrering
                            </a>
                        </h6>
                    </div>
                </template>
            </b-table>
            </div>
            <b-row>
                <b-col md="4"
                       sm="12">
                    <b-form-group label-cols="5"
                                  label-size="sm"
                                  label="Per sida"
                                  label-for="active-lacks--per-page-select">
                        <b-form-select id="active-lacks--per-page-select"
                                       v-model="perPage"
                                       :options="pageOptions"
                                       size="sm"
                                       style="float:left;"></b-form-select>
                    </b-form-group>
                </b-col>

                <b-col md="8"
                       sm="12">
                    <b-pagination v-model="currentPage"
                                  :total-rows="totalRows"
                                  :per-page="perPage"
                                  align="left"
                                  size="sm"
                                  hide-goto-end-buttons
                                  hide-ellipsis></b-pagination>
                </b-col>
            </b-row>
        </div>
</template>

<script>
    import { get } from '@/helpers/api';
    import ControlPositionStatusBall from '@/components/control/ControlPositionStatusBall';
    import {
        getDownloadUrlByArticleId
    } from '@/helpers/file.helper';

    export default {
        name: 'ActiveLacksWidget',
        props: {
            header: String,
            buildingId: {
                type: Number,
                required: false
            },
            customerId: {
                type: String,
                required: false
            }
        },
        components: {
            ControlPositionStatusBall
        },
        data() {
            return {
                isSubmitting: false,
                isLoading: true,
                numberOfDays: 14,
                lacks: [],
                fields: [
                    {
                        key: 'status',
                        label: ' ',
                        thStyle: { 'width': '50px' }
                    },
                    {
                        key: 'buildingName',
                        label: 'Fastighet',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'concatenatedNumbers',
                        label: '#',
                        sortable: true,
                        sortDirection: 'desc',
                        thStyle: { 'width': '100px' }
                    },
                    {
                        key: 'articleName',
                        label: 'Artikel',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'lackDescription',
                        label: 'Brist',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'fixedStatusDescription',
                        label: 'Åtgärdstatus',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    { key: 'hasAttachement', label: 'Bilaga' },
                    {
                        key: 'controlDoneDate',
                        label: 'Rapporterad',
                        sortable: true,
                        sortDirection: 'desc',
                        tdClass: 'text-right',
                        thClass: 'text-right',
                        thStyle: { 'width': '120px' }
                    }
                    //,{
                    //    key: 'controlDoneDate',
                    //    label: 'Avslutad',
                    //    sortable: true,
                    //    sortDirection: 'desc',
                    //    tdClass: 'text-right',
                    //    thClass: 'text-right',
                    //    thStyle: { 'width': '120px' }
                    //}
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [5, 10, 25, 50, 100],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                showFilter: false
            }
        },
        computed: {
            apiAction() {
                if (this.buildingId)
                    return `GetLacksForUserByBuildingId/${this.buildingId}`;
                if (this.customerId)
                    return `GetLacksForUserByCustomerId/${this.customerId}`;
            }
        },
        methods: {
            onContextChanged(ctx) {
                if (ctx.perPage > this.totalRows)
                    this.currentPage = 1;
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
            },
            async getData() {
                await get('Control', this.apiAction)
                    .then((x) => {
                        this.lacks = x.data;
                        this.totalRows = this.lacks.length;
                        this.isLoading = false;
                    })
                    .catch((x) => {
                        this.isLoading = false;
                    });
            },
            getUrl(id) {
                return getDownloadUrlByArticleId(id);
            }
        },
        async mounted() {
            await this.getData();
        }
    };
</script>

<style scoped>
    .table {
        margin-top: 8px;
    }
</style>