import ActiveControls from "./ActiveControls";
import TableWidget from "./TableWidget";
import ChartWidget from "./ChartWidget";


export default {
    ActiveControls,
    TableWidget,
    ChartWidget

}